import React from "react";
import { useContext, useEffect, useState } from "react";
import { BoxConfig } from "../getInput";
import { Upload, UploadFile, UploadProps } from "antd";
import {
  isEmptyArray,
  isExist,
  isFunction,
  judgeLanguage,
  localGetString,
  mustArray,
  notExist,
} from "../../../utils";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { USER_TOKEN } from "../../../config";
import { FormItemContext } from "../../FormWrapper";
import { useGetColor } from "../../../utils/hooks";
import isArray from "lodash/isArray";
import i18n from "@/locales";

const RemoveSvg = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M4 3.11125L7.11125 0L8 0.888749L4.88875 4L8 7.11125L7.11125 8L4 4.88875L0.888749 8L0 7.11125L3.11125 4L0 0.888749L0.888749 0L4 3.11125Z"
        fill={props?.color || "#ABADAC"}
      />
    </svg>
  );
};
const UploadSvg = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 19.2105H20.1053V21H4V19.2105ZM12.9474 7.42505V17.4211H11.1579V7.42505L5.72595 12.8579L4.46079 11.5927L12.0526 4L19.6445 11.5918L18.3793 12.857L12.9474 7.42684V7.42505Z"
        fill="var(--color-textAssist)"
      />
    </svg>
  );
};
export const BasicUpload = (item: BoxConfig, { name }: { name: string }) => {
  const custom = useGetColor();
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [isError, setIsError] = useState(false);
  const [border, setBorder] = useState("border-dividingLine");
  const { error } = useContext(FormItemContext);
  const { t } = useTranslation();
  const disabled = item?.inputOptions?.disabled ?? false;
  const { style, ...inputOptions } = item?.inputOptions || {};

  useEffect(() => {
    if (!isEmptyArray(error)) {
      const isError = mustArray(error).includes(name);
      if (isError) {
        setIsError(true);
        setBorder("border-cError");
      }
    }
  }, [error]);

  useEffect(() => {
    const element = document.querySelector(".ant-upload-select") as any;
    // document.getElementById("myDiv");
    if (isExist(element)) {
      element.style.width = "100%";
    }
  }, []);

  useEffect(() => {
    if (!isArray(item?.value)) return;
    setFileList(mustArray(item?.value).filter(isExist));
  }, [JSON.stringify(item?.value)]);
  const onChange: UploadProps["onChange"] = ({
    file: newFile,
    fileList: newFileList,
    event,
  }) => {
    // 校验文件大小类型， 如果校验不通过，就不setFileList， 通过组件外面控制
    if (isFunction(item?.inputOptions?.beforeUpload)) {
      if (!item?.inputOptions?.beforeUpload(newFile)) return;
    }
    setBorder("border-dividingLine");
    setIsError(false);
    setFileList(newFileList);
  };
  const removeChange = (currentItem: UploadFile) => {
    const currFiled = fileList?.filter(
      (item) => item?.uid !== currentItem?.uid
    );
    setFileList(currFiled);

    item?.inputOptions?.onRemove(currFiled);
  };

  // cError
  return (
    <Upload
      action={
        item?.inputOptions?.action ||
        "/permission-account/common/upload/one/pic"
      }
      fileList={fileList}
      name="file"
      headers={{
        "Steck-Accept-Language": i18n.language,
        Authorization: localGetString(USER_TOKEN),
      }}
      itemRender={() => null}
      {...inputOptions}
      onChange={(...prams) => {
        console.log("prams", prams);
        onChange(...prams);
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    >
      <div
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        className={clsx(
          "cursor-pointer bg-modulesBackground flex min-h-9 h-full items-center justify-between w-full border border-dividingLine border-solid rounded",
          // border,
          isError
            ? "hover:border-cError"
            : disabled
              ? ""
              : "hover:border-primary"
        )}
      >
        {isEmptyArray(fileList) ? (
          <div
            // style={{ color: "var(--color-defaultButtonBg)" }}
            className=" pl-3 text-cAssistTxt"
          >
            {item?.inputOptions?.placeholder
              ? item?.inputOptions?.placeholder
              : t("comChoose")}
          </div>
        ) : (
          <div className=" flex justify-start gap-1 flex-wrap h-full py-1 px-3">
            {mustArray(fileList).map((item, index) => {
              return (
                <div
                  key={`${index + 1}`}
                  className="text-primary bg-selectTagBg rounded pl-1 pr-2 h-[32px] flex items-center"
                >
                  <span className=" p-1">{item?.name}</span>
                  <span
                    onClick={(event) => {
                      if (disabled) return;
                      event.stopPropagation();
                      removeChange(item);
                    }}
                  >
                    <RemoveSvg
                      color={
                        disabled
                          ? "var(--color-primary)"
                          : "var(--color-primary)"
                      }
                    />
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <div className=" pr-1 pt-1">
          <UploadSvg />
        </div>
      </div>
    </Upload>
  );
};
