import dayjs from "dayjs";
import "dayjs/locale/zh-cn"; // 导入所需的语言包
import "dayjs/locale/en";
import "dayjs/locale/eu"; // 导入欧洲地区的语言包
import { isExist, notExist } from ".";
dayjs.locale("en"); // 设置默认语言
export function formatDate(
  date: string | number | dayjs.Dayjs | Date | null | undefined,
  format = "YYYY-MM-DD"
) {
  return date ? dayjs(date)?.format(format) : "--";
}

/**
 * @formatDateNew
 * 解析时间转换为对应format格式函数
 * @date 日期时间
 * @format 可传递自定义格式，固定format格式
 *
 */
export enum DateType {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  TOTAL = "total",
  CUSTOM = "custom",
}
export enum TimeType {
  MM = "MM",
  SS = "SS",
}
export const LANG = {
  zh: "zh-CN",
  en: "en-US",
  de: "de-DE",
  fr: "fr-FR",
  th: "th-TH",
  es: "es-ES",
  it: "it-IT",
  pl: "pl-PL",
};
// 年月日 （系统里面 日, 周）用的这个（DATE_FORMAT是函数里默认格式）
const DATE_FORMAT: { [key: string]: string } = {
  [LANG.zh]: "YYYY-MM-DD", // 中文格式
  [LANG.en]: "DD/MM/YYYY", // 英语格式
  [LANG.de]: "DD.MM.YYYY", // 德语格式
  [LANG.fr]: "DD/MM/YYYY", // 法语格式
  [LANG.th]: "DD/MM/YYYY", //
  [LANG.es]: "DD/MM/YYYY", //
  [LANG.it]: "DD/MM/YYYY", //
  [LANG.pl]: "DD.MM.YYYY", //
};
// 年月
const DATE_FORMAT_MONTH: { [key: string]: string } = {
  [LANG.zh]: "YYYY-MM", // 中文格式
  [LANG.en]: "MM/YYYY", // 英语格式
  [LANG.de]: "MM.YYYY", // 德语格式
  [LANG.fr]: "MM/YYYY", // 法语格式
  [LANG.th]: "MM/YYYY", //
  [LANG.es]: "MM/YYYY", //
  [LANG.it]: "MM/YYYY", //
  [LANG.pl]: "MM.YYYY", //
};
// 年
const DATE_FORMAT_YEAR: { [key: string]: string } = {
  [LANG.zh]: "YYYY", // 中文格式
  [LANG.en]: "YYYY", // 英语格式
  [LANG.de]: "YYYY", // 德语格式
  [LANG.fr]: "YYYY", // 法语格式
  [LANG.th]: "YYYY", //
  [LANG.es]: "YYYY", //
  [LANG.it]: "YYYY", //
  [LANG.pl]: "YYYY", //
};

const DATE_TYPE_FORMAT: {
  [key in keyof typeof LANG]: { [key in DateType]: string };
} = {} as any;

Object.values(LANG).forEach((e) => {
  DATE_TYPE_FORMAT[e] = {
    [DateType.DAY]: DATE_FORMAT[e],
    [DateType.WEEK]: DATE_FORMAT[e],
    [DateType.MONTH]: DATE_FORMAT_MONTH[e],
    [DateType.YEAR]: DATE_FORMAT_YEAR[e],
    [DateType.TOTAL]: DATE_FORMAT_YEAR[e],
  };
});

export type TimeContentType = TimeType.SS | TimeType.MM;
const TIME_TYPE: { [key: string]: { [time: string]: string } } = {
  [LANG.zh]: {
    [TimeType.SS]: "HH:mm:ss",
    [TimeType.MM]: "HH:mm",
  },
  [LANG.en]: {
    [TimeType.SS]: "hh:mm:ss",
    [TimeType.MM]: "hh:mm",
  },
};

// 获取时间格式
export const getTimeFormat = (type?: TimeContentType, showA?: boolean) => {
  if (notExist(type)) return "";
  const local = localStorage?.getItem("changeLang") || "zh-CN";
  const currentLangTime: { [key: string]: string } = TIME_TYPE?.["zh-CN"] || {
    [TimeType.SS]: "hh:mm:ss",
    [TimeType.MM]: "hh:mm",
  };
  if (showA) {
    return `${currentLangTime[type as TimeContentType]} A`;
  }
  //  ["zh-CN"].includes(local) ? TIME_TYPE[type] : `${TIME_TYPE[type]} A` 以后可能改成这样
  return currentLangTime[type as TimeContentType];
};

// 获取日期格式
export type DateContentType =
  | DateType.DAY
  | DateType.WEEK
  | DateType.MONTH
  | DateType.YEAR
  | DateType.TOTAL;

// 不需要Time的时侯这个可以直接用，用在蚂蚁组件 <DatePicker/> || <RangePicker/>
export const getFormat = (dateType: DateContentType) => {
  const local = localStorage?.getItem("changeLang") || "zh-CN";
  const dateFormat: { [key: string]: string } = DATE_TYPE_FORMAT[local];
  return dateFormat[dateType];
};

// 定义验证函数,检查当前传参是不是日期格式
function isValidDate(date?: string | dayjs.Dayjs | number): dayjs.Dayjs | null {
  if (typeof date === "string") {
    // 尝试解析字符串为日期
    const parsed = dayjs(date);
    if (!parsed.isValid()) {
      return null;
    }
    return parsed;
  } else if (dayjs.isDayjs(date)) {
    // 如果已经是dayjs对象，直接返回
    return date;
  } else if (typeof date === "number") {
    // 如果是时间戳，尝试转换
    const fromTimestamp = dayjs(date);
    if (!fromTimestamp.isValid()) {
      return null;
    }
    return fromTimestamp;
  }
  return null;
}

export const formatDateNew = (
  date?: string | dayjs.Dayjs | number,
  isShowTime = true,
  content: {
    dateType: DateContentType;
    timeType?: TimeType.SS | TimeType.MM;
    showA?: boolean;
  } = {
    dateType: DateType.DAY,
    timeType: TimeType.SS,
    showA: false,
  },
  format?: string,
  timeFormat?: string
) => {
  const validDate = isValidDate(date);
  if (!validDate) return "--";
  const { dateType, timeType, showA } = content;
  const currentFormat = isExist(format) ? format : getFormat(dateType);
  const currentTimeFormat = isShowTime
    ? isExist(timeFormat)
      ? timeFormat
      : getTimeFormat(timeType, showA)
    : "";
  const res = `${currentFormat} ${currentTimeFormat}`;
  return dayjs(date)?.format(res);
};
