import React, { useRef, useEffect, useState, useMemo } from "react";
import GoogleMap from "google-map-react";
// import GoogleMap from "google-maps-react-markers";
import supercluster from "supercluster";
import { Tooltip, Divider, Button, message, Spin } from "antd";
import { StatusComponent } from "common";
import SearchComponent from "./searchComponent";
import { getMapList, getStationList, getStationDetail } from "../services/index";
import { PlusOutlined, MinusOutlined, FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { getCurrentLocation } from "common/utils";
import _, { divide, set } from "lodash";
import MarkSvg from "common/svg/markSvg";
import MarkMuliteSvg from "common/svg/markMuliteSvg";
import LocationSvg from "common/svg/locationSvg";
import MarkBoxSvg from "common/svg/markBoxSvg";
import CloseSvg from "common/svg/closeSvg";
import PowerSvg from "common/svg/powerSvg";
import "./index.css";
import { PhotovoltaicSvg, StorageNewSvg } from "../../../svgs/planExort/deviceViewSvg";
import { useTranslation } from "react-i18next";
import AMapLoader from "@amap/amap-jsapi-loader";
import { GAODE_KEY, GAODE_SAFETY_KEY } from "common/config";
import { createRoot } from "react-dom/client";
import { JSX } from "react/jsx-runtime";
import plantbg from "../../../assets/planImgs/plantbg.png";
import plantDarkbg from "../../../assets/planImgs/plantDarkbg.png";
import { formatDateNew } from "common/utils/DateUtils";

interface clustersType {
  type: string;
  id?: number;
  geometry?: {
    type: string;
    status?: number | string;
    coordinates: number[];
  };
  properties: {
    cluster: boolean;
    id: number;
    parentId?: number;
    num?: number;
    cluster_id?: number;
    point_count?: number;
    point_count_abbreviated?: number;
  };
}
interface tooltipDataType {
  visible: {
    [key: number]: boolean;
  };
  data: object;
  multiple?: boolean;
}
interface boundsType {
  ne: { lat: number; lng: number };
  nw: { lat: number; lng: number };
  se: { lat: number; lng: number };
  sw: { lat: number; lng: number };
}
interface centerType {
  lat: number;
  lng: number;
}
interface mapOptionsType {
  bounds: boundsType;
  center: centerType;
  zoom: number;
}
interface locationsType {
  id: number;
  collectState: boolean;
  lat: number;
  lng: number;
  name: string;
  status: number;
  statusNamae?: string;
}
// let globalData: any = [];
let markers: { id: number; marker: any }[] = []; // 创建一个数组来保存所有 Marker
const MapComponent = (props: { positionId?: string }) => {
  // const isCNFlag = localStorage.getItem("iscn") ? (localStorage.getItem("iscn") === "true" ? true : false) : process.env.REACT_APP_ISCN === ""; // 判断是否是国内环境 （国内环境高德，国外google）
  const { t } = useTranslation();
  const { positionId } = props;
  const [isCNFlag, setIsCnFlag] = useState<boolean>(false);
  const [mapOptions, setMapOptions] = useState<mapOptionsType>({
    bounds: {
      ne: { lat: 0, lng: 0 },
      nw: { lat: 0, lng: 0 },
      se: { lat: 0, lng: 0 },
      sw: { lat: 0, lng: 0 },
    },
    center: { lat: 30.523801, lng: 104.098067 }, // 初始地图中心点
    // center: { lat: 0, lng: 0 },
    zoom: 3, // 初始地图缩放级别
  }); // 地图option对象
  const [googleTheme, setGoogleThme] = useState<any>([]);
  const [isFull, setIsFull] = useState<boolean>(false); // 是否全屏
  const [isFinish, setIsFinish] = useState<boolean>(false); // 是否请求结束
  const [stationList, setStationList] = useState<any[]>([]);
  const mapBoxRef = useRef<any>(null); // 地图box实例
  const mapRef = useRef<any>(); // 地图对象实例
  const mapGaodeRef = useRef<any>(); // 高德地图实例
  const gaodeMarkerRef = useRef<any>(null); // 高德地图标记实例
  const infoWindow = useRef<any>(null); // 高德地图弹窗实例
  const superClusterRef = useRef<any>(null); // 聚合实例
  const [currentClick, setCurrentClick] = useState<number | string>(""); // 高德地图当前点击标记
  const [clusters, setClusters] = useState<clustersType[]>([]); // 聚合后的标点数据
  const [locations, setLocations] = useState<locationsType[]>([]); // 地理位置数组
  const [locationsAll, setLocationsAll] = useState<locationsType[]>([]); // 地理位置数组（保存一份全部数据）
  const [opacity, setOpacity] = useState(1); // 做标点特殊处理的
  const [loading, setLoading] = useState<boolean>(false); // loading加载
  const [imageUrl, setImageUrl] = useState(null); // 图片url
  const [currentState, setCurrentState] = useState(-1); // 当前用户所选左上角电站状态
  const globalData: any = useRef([]);
  console.log(1111111);

  // 手动控制地图详情tooltip的显隐
  const [tooltipData, setTooltipData] = useState<tooltipDataType>({
    visible: {},
    data: {},
  });
  // tooltip弹窗底部数据
  const tooltipObj = [
    {
      label: `${t("panelCapacity")}（kWp）`,
      value: "capacity",
      icon: <PhotovoltaicSvg />,
    },
    {
      label: t("homeGenerationPower"),
      value: "generationPower",
    },
    {
      label: t("homeDailyGeneration"),
      value: "dailyGeneration",
    },
    {
      label: t("homeInstalled"),
      value: "gridConnectedTime",
    },
    // {
    //   label: t("homeStorageCapacity"),
    //   value: "storageCapacity",
    //   icon: <StorageNewSvg />,
    //   isStorage: true,
    // },
    {
      label: t("homeAvailableCapacity"),
      value: "availCapacity",
      isStorage: true,
    },
    {
      label: t("homeInstalled"),
      value: "gridConnectedTime",
      isStorage: true,
    },
  ];
  /**
   * 初始化
   */
  useEffect(() => {
    const cn = window?.env?.REACT_APP_ISCN === "1" || process.env.REACT_APP_ENV === "development";
    setLoading(true);
    setIsCnFlag(cn);
    // 强制延迟加载
    setTimeout(() => {
      if (cn) {
        initGaodeMap();
      } else {
        initGooleMap();
      }
      // 拉取下拉菜单数据
      getStationListFn(-1);
      const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
      setImageUrl(currentTheme === "light" ? plantbg : plantDarkbg);
      window.addEventListener("localStorageChange", (e: any) => {
        setImageUrl(e.detail.value === "light" ? plantbg : plantDarkbg);
        setMapTheme(e.detail.value);
      });
    }, 1000);
  }, []);
  const setMapTheme = (val: string) => {
    return;
    const cn = window?.env?.REACT_APP_ISCN === "1" || process.env.REACT_APP_ENV === "development";
    if (cn) {
      mapGaodeRef.current && mapGaodeRef.current.setMapStyle(val === "light" ? "amap://styles/normal" : "amap://styles/dark");
    } else {
      setGoogleThme(
        val === "light"
          ? []
          : [
              {
                elementType: "geometry",
                stylers: [
                  {
                    color: "#212121",
                  },
                ],
              },
              {
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    color: "#212121",
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "administrative.country",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#2c2c2c",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#3e3e3e",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#3e3e3e",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#2c2c2c",
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#2c2c2c",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#1d1d1d",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#3d3d3d",
                  },
                ],
              },
            ],
      );
    }
  };
  /**
   * 创建 supercluster 聚合器
   */
  const setLocationFn = (name?: number) => {
    const val = name === 0 ? name : name || currentState; // 因0为假值 当值为0时 会一直是后面的值
    superClusterRef.current = new supercluster({
      radius: 60, // 聚合半径
      maxZoom: 16, // 最大聚合缩放级别
    });
    let res = [];
    if (val === -1) {
      res = locationsAll;
    } else {
      const arr = locationsAll.filter((item: locationsType) => {
        if (val === 0) return item.status === val;
        // if (currentState !== 0) return item.status === currentState;
        if (val !== 0 && val !== 4) return item.status === val;
        if (val === 4) return item.collectState === true;
      });
      res = arr;
    }
    superClusterRef.current.load(
      res.map((loc: locationsType) => ({
        type: "Feature",
        properties: { cluster: false, id: loc.id },
        geometry: { type: "Point", coordinates: [loc.lng, loc.lat], status: loc.status },
      })),
    );
  };
  useEffect(() => {
    setLocationFn();
    // handleReset();
  }, [locations]);

  /**
   * 更新聚合点
   * @update 更新数据
   * @fixClusters 重新聚合 将重复经纬度坐标注入新类型
   */
  const fixClusters = (data: any[]) => {
    // 存储处理后的结果
    const newArray: any = [];
    const coordMap = new Map();

    // 遍历数据
    data.forEach((item) => {
      const coords = item.geometry.coordinates.toString(); // 将 coordinates 转换为字符串以便比较

      // 如果 point_count 存在，直接保留该对象
      if (item.properties.point_count) {
        newArray.push(item);
      } else {
        // 如果 coordinates 已经存在，表示找到了重复的坐标
        if (coordMap.has(coords)) {
          // 找到已经存在的索引并更新它的类型为 'a'，并更新 num 字段
          const existingIndex = coordMap.get(coords);
          let num = newArray[existingIndex].properties.num;
          newArray[existingIndex].geometry.type = "FeaturePoint"; // 修改类型为 'a'
          newArray[existingIndex].properties.num = num ? (num += 1) : 2; // 更新 num 字段
        } else {
          // 如果 coordinates 没有重复，存储该项并设置 num 为 1
          newArray.push({
            ...item, // 保留原始数据
            geometry: {
              ...item.geometry,
              type: "Point", // 修改类型为 'a'
            },
            properties: {
              ...item.properties,
            },
          });
          // 将坐标添加到 Map 中，记录当前索引
          coordMap.set(coords, newArray.length - 1);
        }
      }
    });
    return newArray;
  };
  const update = () => {
    const { zoom, bounds } = mapOptions;
    const clusterBounds = [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat];
    const clustersCurrent = superClusterRef.current.getClusters(clusterBounds, zoom);
    // 用于存储处理后的结果

    const res: clustersType[] = fixClusters(clustersCurrent);
    setClusters(res);
    if (isCNFlag) {
      getGaodeMarker(res);
    }
  };
  useEffect(() => {
    update();
  }, [mapOptions.zoom, JSON.stringify(mapOptions.bounds), currentClick]);

  /**
   * 监听列表页点击定位后触发
   */
  useEffect(() => {
    if (loading) {
      message.error(t("homeNoLoading"));
      return;
    }
    const id = positionId?.split("&&")[0];
    console.log(id, "32432432");
    if (id) {
      if (+id !== 0) {
        handleReset();
        getMapList({ stationId: +id }).then((result: any) => {
          const res = result.data[0];
          if (!res.lat || !res.lng) {
            message.open({
              type: "error",
              content: t("homeNoLocation"),
            });
            return;
          }
          const center = {
            lat: res.lat,
            lng: res.lng,
          };

          if (isCNFlag) {
            setCurrentClick(+id);
            mapGaodeRef.current.setZoom(17, true);
            mapGaodeRef.current.setCenter([center.lng, center.lat], true);
          } else {
            setMapOptions({
              ...mapOptions,
              zoom: 20,
              center,
            });
          }
          setTimeout(() => {
            fixAboutTooltip({
              type: "Feature",
              properties: {
                cluster: false,
                id: +id,
              },
            });
          }, 500);
        });
      }
    }
  }, [positionId]);

  /**
   * @handleReset
   * 重置一下tooltip弹框
   */
  const handleReset = () => {
    if (isCNFlag) {
      // 这里使用官方api关闭窗体 但是dom没有删除？ 所以手动删除dom防止dom过多影响性能
      mapGaodeRef.current && mapGaodeRef.current.clearInfoWindow();
      const dom = document.getElementsByClassName("amap-info") as any;
      for (let i = 0; i < dom.length; i++) {
        dom[i].remove();
      }
    }
    setTooltipData({
      visible: {},
      data: {},
    });
  };

  /**
   * @getStationListFn 获取电站列表数据
   * @state 当前所选状态 -1 全部 1：正常 2：故障 0：离线 4：关注
   * @name 可选输入电站名称
   */
  const getStationListFn = _.debounce((state: number, name?: string) => {
    const params: {
      pageIndex: number;
      pageSize: number;
      stationQuery: string;
      runState?: number[];
      isCollected?: number;
    } = {
      pageIndex: 1,
      pageSize: 200, // 限制默认200条数据
      stationQuery: name || "",
    };
    if (state !== -1) {
      if (state !== 4) params.runState = [state];
      if (state === 4) params.isCollected = 0;
    }
    getStationList(params).then((res: any) => {
      if (!res.data) return;
      console.log(res.data, "32432");
      setStationList(res.data.data);
    });
  }, 500);

  /**
   * @getInit 获取数据
   */
  const normalizeCoord = (coord: any) => (coord / 360 > 1 ? coord % 360 : coord);
  const getInit = async (center: centerType, bounds: boundsType, zoom: number) => {
    const parmas = {
      center: {
        lat: normalizeCoord(center.lat),
        lng: normalizeCoord(center.lng),
      },
      bounds: {
        ne: {
          lat: normalizeCoord(bounds.ne.lat),
          lng: normalizeCoord(bounds.ne.lng),
        },
        nw: {
          lat: normalizeCoord(bounds.nw.lat),
          lng: normalizeCoord(bounds.nw.lng),
        },
        se: {
          lat: normalizeCoord(bounds.se.lat),
          lng: normalizeCoord(bounds.se.lng),
        },
        sw: {
          lat: normalizeCoord(bounds.sw.lat),
          lng: normalizeCoord(bounds.sw.lng),
        },
      },
    };
    try {
      const res: any = await getMapList(parmas);
      if (!res.data) return;
      globalData.current = res.data;
      await setLocations(res.data || []);
      await setLocationsAll(res.data || []);
      await setMapOptions({ center, zoom, bounds });
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * @initGaodeMap 谷歌初始化
   */
  const initGooleMap = () => {
    // 拉取用户当前坐标
    getCurrentLocation().then((res) => {
      console.log(res, "res");
      setLoading(false);
      setTimeout(() => {
        setMapOptions({
          bounds: mapOptions.bounds,
          center: { lat: res.lat, lng: res.lng },
          zoom: res.isReject ? 4 : mapOptions.zoom,
        });
        setIsFinish(true);
        // const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
        // setMapTheme(currentTheme);
      }, 500);
    });
  };
  /**
   * @initGaodeMap 高德初始化
   */
  const initGaodeMap = async () => {
    // @ts-ignore
    window._AMapSecurityConfig = {
      securityJsCode: GAODE_SAFETY_KEY,
    };
    AMapLoader.load({
      key: GAODE_KEY,
      version: "2.0",
      plugins: ["AMap.Marker", "AMap.Geocoder", "AMap.PlaceSearch"],
    })
      .then(async (AMap) => {
        setLoading(false);
        // mapInstance = AMap;
        const center: { lat: number; lng: number; isReject: boolean } = (await getCurrentLocation()) || { lat: 0, lng: 0, isReject: false };
        mapGaodeRef.current = new AMap.Map("containerModal", {
          mapStyle: "amap://styles/normal",
          zooms: [4, 18], // 设置缩放级别范围，最小缩放级别3，最大缩放级别18
          zoom: center.isReject ? 4 : mapOptions.zoom,
          center: [center.lng, center.lat],
        });
        setIsFinish(true);
        const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
        setMapTheme(currentTheme);
        await getGaodeInfo();
        // drageStart可以通过e判断是手动行为还是自动行为
        mapGaodeRef.current?.on("dragstart", (e: any) => {
          if (e) {
            setCurrentClick("");
            handleReset();
          }
        });
        mapGaodeRef.current?.on("moveend", () => {
          getGaodeInfo();
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  /**
   * @getGaodeInfo 高德获取信息
   */
  const bounds = () => {
    const bounds = mapGaodeRef.current.getBounds();
    const { northEast, southWest } = bounds;
    const northWest = new AMap.LngLat(northEast.getLng(), southWest.getLat()) || 0; // 西北角
    const southEast = new AMap.LngLat(southWest.getLng(), northEast.getLat()); // 东南角
    return {
      ne: { lat: northEast.lat || 0, lng: northEast.lng || 0 },
      nw: { lat: northWest.lat || 0, lng: northWest.lng || 0 },
      se: { lat: southEast.lat || 0, lng: southEast.lng || 0 },
      sw: { lat: southWest.lat || 0, lng: southWest.lng || 0 },
    };
  };
  const getGaodeInfo = async () => {
    const center: centerType = mapGaodeRef.current.getCenter();
    const zoom = mapGaodeRef.current.getZoom();
    await getInit(
      {
        lat: center.lat,
        lng: center.lng,
      },
      bounds(),
      zoom,
    );
  };

  /**
   * @getGaodeMarker 高德创建标记
   * 因为高德地图无法直接使用reactDom 所以要用createRoot包裹
   */
  const createContent = (reactdom: string | number | boolean | JSX.Element | Iterable<React.ReactNode> | null | undefined) => {
    const dom = document.createElement("div");
    const content = createRoot(dom);
    content.render(reactdom);
    return dom;
  };
  const getGaodeMarker = async (data: clustersType[]) => {
    // 每次过去marker前 先清空所有marker 优化性能
    if (markers.length > 0) {
      markers.forEach((item: any) => {
        mapGaodeRef.current && mapGaodeRef.current.remove(item.marker);
      });
    }
    try {
      markers = [];
      data.forEach((item: clustersType) => {
        const { id, cluster_id, cluster: isCluster } = item.properties || item;
        const [lng, lat] = (item && item.geometry && item.geometry.coordinates) || [];
        gaodeMarkerRef.current = new AMap.Marker({
          position: [lng, lat],
          content: isCluster
            ? createContent(<Marker key={`cluster-${item.id}`} lat={lat} lng={lng} cluster={item} />)
            : createContent(Detail(item, lat, lng, item.geometry?.type === "Point" ? "singal" : "multiple")),
        });
        markers.push({ id, marker: gaodeMarkerRef.current }); // 将 Marker 和 ID 存储到数组中
        mapGaodeRef.current?.add(gaodeMarkerRef.current);
      });
    } catch (error) {
      console.log(error, "error");
    }
  };
  /**
   * @fixSomeLocation 根据状态以及经纬度过滤电站
   * @param lat
   * @param lng
   * @returns
   */
  const fixSomeLocation = (lat: number | string, lng: number | string) => {
    const someLocation = locations.filter((item: locationsType) => {
      const prev = item.lat === lat && item.lng === lng;
      if (currentState === -1) return prev;
      if (currentState === 0) return prev && (item.status === currentState || item.status === null);
      if (currentState === 4) return prev && item.collectState === true;
      return prev && item.status === currentState;
    });
    return someLocation;
  };
  /**
   * @fixAboutTooltipGaode 对点击后tooltip的效果进行逻辑处理(高德)
   * @cluster 聚合数据
   * @data 聚合数据
   * @type 判断是否是多个电站
   */
  const fixAboutTooltipGaode = (data: any, clusterId: number, type?: string) => {
    const someLocation = data;
    const id = clusterId;
    console.log(markers, "markers");
    const foundMarker = markers.find((m: any) => m.id === id);
    infoWindow.current = new AMap.InfoWindow({
      isCustom: true, // 使用自定义窗体
      // autoMove: true,
      content: createContent(
        <div className="w-[450px] max-w-[450px] bg-modulesBackground getTooltipContent rounded">{getTooltipContent(someLocation, type)}</div>,
      ),
      offset: new AMap.Pixel(16, -55),
      anchor: "bottom-center",
    });
    setTimeout(() => {
      infoWindow.current.open(mapGaodeRef.current, foundMarker?.marker.getPosition());
    }, 200);
  };
  /**
   * @fixAboutTooltip 对点击后tooltip的效果进行逻辑处理
   * @param cluster 聚合数据
   */

  const fixAboutTooltip = async (cluster: clustersType, data?: any) => {
    handleReset();
    const currentId = globalData.current.filter((item: any) => item.id === cluster.properties.id)[0];
    let currentLat: any[] = [];
    let first: any = {};
    if (currentId) {
      currentLat = globalData.current.filter((item: any) => item.lat === currentId.lat && item.lng === currentId.lng);
      if (isCNFlag) {
        const mapsId = markers.map((ele: any) => ele.id);
        first = currentLat.filter((eleC: any) => mapsId.indexOf(eleC.id) !== -1)[0];
      } else {
        // 这里是保证唯一性 确保相同经纬相同状态就一个电站的时候 就取自己id就好了
        if (data) {
          first = {
            id: data[0].id,
          };
        }
      }
    }
    const firstId = first?.id || currentLat[0]?.id || 0;
    const id = cluster.properties.id || cluster.id || 1;
    const parentId = cluster.properties.parentId || "";
    const res = await getStationDetail(id);
    if (!res.data) return;
    if (isCNFlag) {
      fixAboutTooltipGaode(res.data, parentId ? parentId : firstId ? firstId : id, "");
      return;
    }
    setTooltipData({
      visible: {
        [parentId ? parentId : firstId ? firstId : id]: true,
      },
      data: res.data || {},
    });
  };

  /**
   * @handleClickMarkDetail 当点击具体电站时触发
   * @param cluster 聚合数据
   */
  const handleClickMarkDetail = async (cluster: clustersType, type?: string) => {
    if (type) {
      const [lng, lat] = (cluster && cluster.geometry && cluster.geometry.coordinates) || [];
      const someLocation = fixSomeLocation(lat, lng);
      console.log(someLocation, "someLocationsomeLocation");
      // 如果同一个经纬度有2个及以上电站
      if (someLocation.length >= 2) {
        const id = cluster.properties.id || cluster.id || 1;
        setTooltipData({
          multiple: true,
          visible: {
            [id]: true,
          },
          data: someLocation,
        });
      } else {
        fixAboutTooltip(cluster, someLocation);
      }
    } else {
      fixAboutTooltip(cluster);
    }
  };

  /**
   * @getTooltipContent 点击电站详情dom
   * @param cluster 当前数据
   * @returns
   */
  const getTooltipContent = (data: any, type?: string): React.ReactNode => {
    console.log(data, "datadata");
    if (Object.keys(data).length === 0) return "";
    console.log(type, "ewrwer");
    console.log(data, "datadata");
    console.log(tooltipData, "1212");
    if (type) {
      return (
        <div className="flex justify-between p-3 rounded">
          <ul className="list-none mt-6 p-0 w-full tooltipContent">
            {data.map((item: any) => {
              return (
                <li
                  key={item.id}
                  className="h-[36px] flex items-center cursor-pointer text-cMainTxt hover:bg-selectedColor p-1 border-b-[1px] border-[0px] border-solid border-dividingLine"
                  onClick={() => {
                    fixAboutTooltip({
                      type: "Feature",
                      properties: {
                        cluster: false,
                        id: item.id,
                        parentId: data[0].id, // 因为同坐标的数据都聚合了 所以我仅仅只有第一个的id 所以这里通用第一个的id作为tooltip窗体进行判断，仅改变里面内容
                      },
                    });
                  }}
                >
                  <span className="relative">
                    <span
                      className="absolute w-[6px] h-[6px] rounded-[50%] block -right-[2px] bottom-[4px]"
                      style={{
                        background: item.status === 1 ? "#21B878" : item.status === 0 ? "#6E7280" : item === 2 ? "#EF4444" : "#5F6166",
                      }}
                    ></span>
                    <PowerSvg />
                  </span>
                  <span className="ml-2">{item.name}</span>
                </li>
              );
            })}
          </ul>
          <div>
            <span
              className="cursor-pointer"
              onClick={() => {
                handleReset();
                setCurrentClick("");
              }}
            >
              <CloseSvg color="var(--color-textAssist)" />
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="bg-modulesBackground p-[20px] rounded">
        <div className="flex gap-4 items-start">
          <div className={`${data.imageUrl ? "w-[120px] h-[80px]" : ""}`}>
            <img src={data.imageUrl || imageUrl} alt="" className={`w-[120px] h-[80px] max-w-[120px] rounded-sm`} />
            {/* {data.imageUrl ? (
              <img src={data.imageUrl} alt="" className="w-[120px] h-[80px] max-w-[120px]" />
            ) : (
              <img src="" alt="" />
            )} */}
          </div>
          <div className="w-[70%]">
            <div className="flex items-center">
              <span className="flex items-center gap-2 flex-1 w-[70%]">
                <span
                  title={data.name}
                  className="max-w-[150px] text-cMainTxt font-semibold text-base overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {data.name || ""}
                </span>
                <span>
                  <StatusComponent
                    flag={data.status === 1 ? "success" : data.status === 0 ? "info" : data.status === 2 ? "error" : "info"}
                    value={
                      data.status === 1
                        ? t("homeRunNormal")
                        : data.status === 0
                          ? t("homeRunOffline")
                          : data.status === 2
                            ? t("homeRunFault")
                            : t("homeRunActivations")
                    }
                  />
                </span>
              </span>
              <span
                className="flex items-center ml-2 cursor-pointer"
                onClick={() => {
                  handleReset();
                  setCurrentClick("");
                }}
              >
                <CloseSvg color="var(--color-textAssist)" />
              </span>
            </div>
            <div className="text-cMinorTxt mt-3 flex items-">
              <span className="inline align-text-top mr-1 mt-[2px]">
                <LocationSvg style={{ marginTop: "2px" }} />
              </span>
              <span className="ml-1">{data.address}</span>
            </div>
          </div>
        </div>
        <Divider style={{ margin: 0, marginTop: 16, marginBottom: 16 }} className=" border-dividingLine" />
        <div>
          {tooltipObj.map((item) => {
            return (
              <>
                {(item.isStorage && data.storageExist) || !item.isStorage ? (
                  <div className="mb-2 flex items-center text-cMainTxt" key={item.value}>
                    <span className="w-[24px] mr-2">{item.icon ? item.icon : null}</span>
                    <span className="block min-w-[160px] text-left ">{item.label} : </span>
                    <span className="ml-4">
                      {item.value === "gridConnectedTime"
                        ? formatDateNew(data[item.value], false)
                        : item.value === "generationPower"
                          ? data[item.value]
                            ? (data[item.value] / 1000).toFixed(2)
                            : data[item.value] === 0
                              ? 0
                              : "--"
                          : item.value === "dailyGeneration" && data[item.value] !== null
                            ? data[item.value] === 0
                              ? 0
                              : Number(data[item.value]?.toFixed(2))
                            : data[item.value] || "--"}
                    </span>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  /**
   * @Marker 自定义的标记聚类组件
   */
  const Marker = ({ lat, lng, cluster }: { lat: number | string; lng: number | string; cluster: clustersType }) => (
    <div
      style={{
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        opacity: opacity,
      }}
      // className="marker"
      className="marker text-primary font-bold text-sm rounded-[50%] bg-white border-4 border-primary border-solid w-[36px] h-[36px] text-center"
      onClick={() => {
        // 获取集群内部第一个子节点
        const firstChildren = superClusterRef.current.getLeaves(cluster.id)[0].geometry.coordinates;
        if (isCNFlag) {
          mapGaodeRef.current.setZoom(17, true);
          mapGaodeRef.current.setCenter(firstChildren, true);
          return;
        }
        mapRef.current.map_.setCenter({
          lat: firstChildren[1],
          lng: firstChildren[0],
        });
        mapRef.current.map_.setZoom(17);
      }}
    >
      {/* <MarkBoxSvg className="marker" /> */}
      <span className="block mt-[6.5%]">{cluster?.properties?.point_count}</span>
    </div>
  );
  /**
   * @MarkerDetail 自定义的标记具体点组件
   */
  const MarkerDetail = ({ lat, lng, cluster, type }: { lat: number | string; lng: number | string; cluster: clustersType; type: string }) => (
    <Tooltip
      placement="top"
      getPopupContainer={() => {
        const dom = document.getElementById("mapBox");
        return dom || document.body;
      }}
      title={() =>
        tooltipData.multiple
          ? getTooltipContent(tooltipData.data, "multiple")
          : tooltipData.visible[cluster.properties.id || 1]
            ? getTooltipContent(tooltipData.data)
            : null
      }
      trigger="click"
      open={tooltipData.visible[cluster.properties.id || 1]}
      color="var(--color-modulesBackground)"
      overlayStyle={{
        width: "450px",
        maxWidth: "450px",
        borderRadius: "4px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
      }}
    >
      {Detail(cluster, lat, lng, type)}
    </Tooltip>
  );
  const Detail = (cluster: clustersType, lat: number | string, lng: number | string, type: string) => {
    const data: clustersType = cluster; // 在这里因为前面用的createRoot包裹，所以直接访问cluster属性会导致ts类型推断错误，所以设定了一个变量进行接收；
    return (
      <div
        style={{
          opacity: opacity,
          position: "absolute",
          top: "100%",
          left: "50%",
          transform: `translate(-50%, ${tooltipData.visible[data.properties.id || 1] ? "-100%" : "-100%"})`,
        }}
        className="markerDetail cursor-pointer"
        onClick={() => {
          console.log(11111);
          if (isCNFlag) {
            setCurrentClick(data.properties.id);
            const someLocation = fixSomeLocation(lat, lng);
            if (type === "multiple") {
              fixAboutTooltipGaode(someLocation, data.properties.id, "multiple");
            } else {
              fixAboutTooltip(cluster);
            }
          } else {
            handleClickMarkDetail(cluster, "isClick");
          }
        }}
      >
        {type === "singal" ? (
          <>
            <span
              className={`${isCNFlag ? (data.properties.id === currentClick ? "" : "hidden") : tooltipData.visible[data.properties.id || 1] ? "" : "hidden"} markerDetail-${cluster.properties.id} absolute -left-[25px] markerChildren`}
            >
              <MarkBoxSvg />
            </span>
            <span className="relative z-50">
              <MarkSvg
                color={
                  data?.geometry?.status === 1
                    ? "#21B878"
                    : data?.geometry?.status === 0
                      ? "#6E7280"
                      : data?.geometry?.status === 2
                        ? "#EF4444"
                        : "#5F6166"
                }
              />
            </span>
          </>
        ) : (
          <span className="relative flex justify-center">
            <MarkMuliteSvg />
            <span className="absolute mt-[6.5%] text-[#347ef8] text-sm">{data.properties.num}</span>
          </span>
        )}
      </div>
    );
  };
  /**
   * @handleChange 当切换状态时触发
   * @name 状态
   */
  const handleChange = async (name: number) => {
    handleReset();
    await setCurrentState(name);
    console.log(name, "32432");
    console.log(locationsAll);
    setLocationFn(name);
    await getStationListFn(name);
    await update();
  };
  /**
   * @handleSearch 当点击搜索时触发
   * @param searchValue
   *  @name 电站名
   *  @state 电站状态
   */
  const handleSearch = async (searchValue: { name: number; state: number }) => {
    handleReset();
    const parmas: {
      stationId: number;
      runState?: number[];
      isCollected?: number;
    } = {
      stationId: searchValue.name,
    };
    if (!searchValue.name) return;
    if (searchValue.state !== -1) {
      if (searchValue.state !== 4) parmas.runState = [searchValue.state];
      if (searchValue.state === 4) parmas.isCollected = 0;
    }
    const result: any = await getMapList(parmas);
    const res = result.data[0];
    if (!res.lat || !res.lng) {
      // message.error(t("homeMapNoFound"));
      return;
    }
    const center = {
      lat: res.lat,
      lng: res.lng,
    };
    if (isCNFlag) {
      setCurrentClick(res.id);
      mapGaodeRef.current.setZoom(17, true);
      mapGaodeRef.current.setCenter([center.lng, center.lat], true);
      setTimeout(() => {
        fixAboutTooltip({
          type: "Feature",
          properties: {
            cluster: false,
            id: res.id,
          },
        });
      }, 500);
    } else {
      // 如果在视野范围的电站 则不用重新渲染可视区域
      const hasNode = clusters.filter((item) => item.properties.id === res.id);
      if (hasNode && hasNode.length === 0) {
        await setMapOptions({
          ...mapOptions,
          zoom: 18,
          center,
        });
      }
      setTimeout(() => {
        handleClickMarkDetail({
          type: "Feature",
          properties: {
            cluster: false,
            id: res.id,
          },
        });
      }, 500);
    }
  };
  /**
   * @handleClickControl 当点击control控件时触发
   * @type 类型
   */
  const handleClickControl = (type: "add" | "reduce" | "reset") => {
    let zoom = isCNFlag ? mapGaodeRef.current.getZoom() : mapRef.current.map_.getZoom();
    if ((type === "add" && zoom >= 18) || (type === "reduce" && zoom <= 4)) return;
    switch (type) {
      case "add":
        zoom += 1;
        break;
      case "reduce":
        zoom -= 1;
        break;
      default:
        zoom = 5;
    }
    if (isCNFlag) {
      const center = [mapGaodeRef.current.getCenter().lng, mapGaodeRef.current.getCenter().lat];
      mapGaodeRef.current.setZoom(zoom, true);
      mapGaodeRef.current.setCenter(center, true);
      getInit(
        {
          lat: center[1],
          lng: center[0],
        },
        bounds(),
        zoom,
      );
    } else {
      mapRef.current.map_.setZoom(zoom);
      setMapOptions({
        ...mapOptions,
        zoom,
      });
    }
  };
  return (
    <>
      {loading && (
        <div className="relative bg-allBackground  z-[1000] h-full w-full">
          <Spin className="absolute top-1/2 right-1/2"></Spin>
        </div>
      )}
      <div className="relative mapBoxNoFull" ref={mapBoxRef} id="mapBox">
        {isCNFlag ? (
          <div className="w-500 h-full relative" id="containerModal"></div>
        ) : (
          isFinish && (
            <GoogleMap
              ref={mapRef}
              bootstrapURLKeys={{
                key: "AIzaSyAMKP6ioiWYGgupPx6gs4PSNCyWyiOtwjM",
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              defaultCenter={mapOptions.center}
              defaultZoom={mapOptions.zoom}
              center={mapOptions.center}
              zoom={mapOptions.zoom}
              disableDefaultUI={true}
              resetBoundsOnResize={true}
              options={{
                styles: googleTheme,
                maxZoom: 18,
                minZoom: 4,
                restriction: {
                  latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
                  strictBounds: true,
                },
              }}
              onGoogleApiLoaded={async (data: any) => {
                console.log(data.map, "map");
              }}
              onDrag={() => {
                setOpacity(0);
                setTooltipData({
                  visible: {},
                  data: {},
                });
              }}
              onDragEnd={() => {
                // setOpacity(0);
                // setTimeout(() => {
                setOpacity(1);
                // }, 300);
              }}
              onChange={async ({ center, zoom, bounds }: any) => {
                console.log(zoom, "1212121212212");
                await getInit(center, bounds, zoom);
              }}
            >
              {clusters.map((item: clustersType) => {
                const { id, cluster: isCluster } = item.properties;
                const [lng, lat] = (item && item.geometry && item.geometry.coordinates) || [];
                if (isCluster) {
                  return <Marker key={`cluster-${item.id}`} lat={lat} lng={lng} cluster={item}></Marker>;
                }
                console.log(item, "trtreter");
                return (
                  <MarkerDetail key={id} lat={lat} lng={lng} cluster={item} type={item.geometry?.type === "Point" ? "singal" : "multiple"}>
                    {/* <div style={{ color: "blue" }}>地点标记</div> */}
                  </MarkerDetail>
                );
              })}
            </GoogleMap>
          )
        )}

        {/* 以下是右上角控制组件 */}
        {!loading && (
          <div className="control absolute right-1 top-6 flex">
            <div className="shadow-div bg-modulesBackground rounded flex gap-2 items-center mr-2 px-3 py-2 cursor-pointer">
              <PlusOutlined
                // let zoom = isCNFlag ? mapGaodeRef.current.getZoom() : mapRef.current.map_.getZoom();
                className={`${mapGaodeRef?.current?.getZoom() === 18 || mapRef?.current?.map_?.getZoom() === 18 ? "!cursor-not-allowed text-cDisableTxt" : ""}`}
                onClick={() => {
                  handleClickControl("add");
                }}
              />
              <MinusOutlined
                className={`${mapGaodeRef?.current?.getZoom() === 4 || mapRef?.current?.map_?.getZoom() === 4 ? "!cursor-not-allowed text-cDisableTxt" : ""}`}
                onClick={() => {
                  handleClickControl("reduce");
                }}
              />
            </div>
            <div
              className="shadow-div bg-modulesBackground rounded px-3 py-2 cursor-pointer mr-6"
              onClick={() => {
                handleChange(-1);
                mapBoxRef.current.className = `relative ${isFull ? "mapBoxNoFull" : "mapBoxIsFull"}`;
                setIsFull(!isFull);
              }}
            >
              {isFull ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </div>
          </div>
        )}

        {/* 以下是左上角筛选框 */}
        {isFull ? (
          <div className="absolute left-6 top-6">
            <SearchComponent
              defaultValue={currentState}
              stationList={stationList}
              getStationListFn={(state: number, name: string) => getStationListFn(state, name)}
              handleSearch={(searchValue: any) => handleSearch(searchValue)}
              handleChange={(name: number) => handleChange(name)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MapComponent;
