import React from "react";
import { Input } from "antd";

import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";
import { t } from "i18next";

// placeholder={t("comPleaseInput")}

const { TextArea } = Input;

export const BasicTextArea = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  return (
    <TextArea
      rows={5}
      style={{ lineBreak: "anywhere" }}
      placeholder={t("comChoose")}
      maxLength={7}
      disabled={item.disabled}
      {...item.inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    />
  );
};
