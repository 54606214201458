import i18n from "i18next";
import enUsTrans from "./modules/en-US.json";
import zhCnTrans from "./modules/zh-CN.json";
import deDeTrans from "./modules/de-DE.json";
import frDrTrans from "./modules/fr-FR.json";
import thTHTrans from "./modules/th-TH.json";
import esESTrans from "./modules/es-ES.json";
import itITTrans from "./modules/it-IT.json";
import plPLTrans from "./modules/pl-PL.json";

import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { judgeLanguage } from "common/utils";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "en-US": {
        translation: enUsTrans,
      },
      "zh-CN": {
        translation: zhCnTrans,
      },
      "de-DE": {
        translation: deDeTrans,
      },
      "fr-FR": {
        translation: frDrTrans,
      },
      "th-TH": {
        translation: thTHTrans,
      },
      "es-ES": {
        translation: esESTrans,
      },
      "it-IT": {
        translation: itITTrans,
      },
      "pl-PL": {
        translation: plPLTrans,
      },
    },
    fallbackLng: "en-US",
    lng: judgeLanguage(),
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
